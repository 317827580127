<template>
  <b-modal
    id="tiss-guide-file-modal"
    title="Adicionar novo arquivo"
    hide-header
    hide-footer
    centered
    size="lg"
    @hidden="cleanModal"
  >
    <div class="modal-header d-flex flex-row justify-content-between">
      <p class="modal-title">
        {{ file && file.filename ? 'Editar ' : 'Adicionar novo arquivo ' }}
      </p>
      <v-close class="icon" @click="cleanModal" />
    </div>
    <div class="modal-body">
      <div class="form-group md-content">
        <label for="file">Nome do arquivo</label>
        <b-input
          autocomplete="off"
          id="file"
          placeholder="Digite o nome do arquivo..."
          v-model="fileName"
        />
      </div>
      <div class="file-atach md-content">
        <div
          class="file-container"
          :class="{ 'without-file': !this.File.name }"
          @dragover.prevent
          @drop.prevent
        >
          <div @drop="dragFile" class="drag-area">
            <div v-if="File.length <= 0">
              <p>Arraste os arquivos aqui</p>

              <div class="separator">
                <HorizontalLine /> ou <HorizontalLine />
              </div>

              <label for="add-file-input" class="add-btn">
                Anexar arquivos
              </label>
              <input
                id="add-file-input"
                type="file"
                accept=".jpg, .png, .pdf, .jpeg, .jps, .tiff"
                @change="addFile"
              />
            </div>
            <div class="file" v-else>
              <div>
                <Pdf v-if="File.type == 'application/pdf'" />
                <Files v-else />
                {{ File.name }}
              </div>
              <v-close class="close" @click="removeFile" />
            </div>
            <b-progress
              class="progress"
              v-if="File && progress"
              :max="100"
              show-progress
              :animated="progress !== 100"
              :variant="!error ? 'info' : 'danger'"
            >
              <b-progress-bar :value="progress">
                <span>
                  <strong>{{ progress }}%</strong>
                </span>
              </b-progress-bar>
            </b-progress>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          class="save-file"
          v-if="file && file.filename"
          @click="updateFile"
        >
          Editar arquivo
        </button>
        <button class="save-file" v-else @click="uploadFile">
          Salvar arquivo
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import HorizontalLine from '@/assets/icons/line.svg'
import Pdf from '@/assets/icons/pdf.svg'
import Files from '@/assets/icons/files.svg'
import Close from '@/assets/icons/close.svg'
import { getCurrentClinic } from '@/utils/localStorageManager'

export default {
  name: 'TissGuideFileModal',
  props: {
    file: Object,
    tissGuide: Object
  },
  components: {
    HorizontalLine,
    Pdf,
    Files,
    'v-close': Close
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      fileName: '',
      progress: null,
      error: false,
      File: []
    }
  },
  methods: {
    cleanModal() {
      this.$bvModal.hide('tiss-guide-file-modal')
      this.progress = null
      this.error = false
      this.fileName = ''
      this.File = []
      this.$emit('clean-modal')
    },
    async uploadFile() {
      if (this.File && this.fileName) {
        const dataForm = new FormData()
        dataForm.append('file', this.File)
        dataForm.append('filename', this.fileName)
        dataForm.append('clinic_id', this.clinic.id)
        dataForm.append('tiss_guide_id', this.tissGuide.id)

        const isLoading = this.$loading.show()
        try {
          const { data } = await this.api.createNewTissGuideFile(dataForm, {
            onUploadProgress: event => {
              this.progress = Math.round((event.loaded * 100) / event.total)
            }
          })
          this.$toast.success('Arquivo enviado com sucesso')
          this.error = false
          this.$emit('uploadNewDocument', data)
          this.$bvModal.hide('tiss-guide-file-modal')
        } catch (error) {
          this.$toast.error(error.message)
          this.error = true
        } finally {
          isLoading.hide()
        }
      } else {
        this.$toast.warning('Por favor, preencha todas informações.')
      }
    },
    async updateFile() {
      if (this.File && this.fileName) {
        const dataForm = new FormData()
        dataForm.append('file', this.File)
        dataForm.append('filename', this.fileName)

        const isLoading = this.$loading.show()
        try {
          const { data } = await this.api.updateNewTissGuideFile(
            this.file.id,
            dataForm,
            {
              onUploadProgress: event => {
                this.progress = Math.round((event.loaded * 100) / event.total)
              }
            }
          )
          this.$toast.success('Arquivo atualizado com sucesso')
          this.error = false
          this.$emit('update-file', data)
          this.$bvModal.hide('tiss-guide-file-modal')
        } catch (error) {
          this.$toast.error(error.message)
          this.error = true
        } finally {
          isLoading.hide()
        }
      } else {
        this.$toast.warning('Por favor, preencha todas informações.')
      }
    },
    removeFile() {
      this.progress = null
      this.error = false
      this.File = []
    },
    addFile(e) {
      this.progress = null
      this.error = false
      this.File = e.target.files[0]
    },
    dragFile(e) {
      this.progress = null
      this.error = false
      const file = e.dataTransfer.files[0]
      const fileType = file.type
      this.fileExtension = fileType.split('/')[1]
      let nameExt = file.name.match(/(?:\.)([0-9a-z]+)$/i)
      nameExt = nameExt.length > 1 ? nameExt[1] : ''
      if (
        ![
          'pdf',
          'jpg',
          'jpeg',
          'png',
          'jps',
          'application/pdf',
          'image/jpg',
          'image/jpeg',
          'image/png',
          'tiff'
        ].includes(fileType || nameExt)
      ) {
        return this.$toast.error('Formato de arquivo não suportado!')
      }
      this.File = e.dataTransfer.files[0]
    }
  },
  watch: {
    file: {
      handler(newValue) {
        if (newValue) {
          this.fileName = newValue.filename
        }
      },
      immediate: true
    },
    fileName(value) {
      if (value) {
        this.fileName = this.fileName
          .replace(/\.[0-9a-z]+$/i, '')
          .replace(/[^\w\sA-Za-zÀ-ÖØ-öø-ÿ-]/g, '')
      }
    }
  }
}
</script>

<style lang="scss">
#tiss-guide-file-modal {
  .modal-content {
    .modal-header {
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px !important;

      .modal-title {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: var(----type-active);
      }

      .icon {
        width: 25px;
        fill: var(--neutral-500);
      }
    }
    border-radius: 8px;

    .modal-body {
      padding: 0 !important;
      margin: 0 !important;

      .file-atach {
        .file-container {
          height: 100px;
          background: transparent;
          border-top: 1px solid var(--neutral-200);
          border-bottom: 1px solid var(--neutral-200);

          &.without-file {
            height: 160px;
            border: 1px dashed var(--blue-500);
            box-sizing: border-box;
            border-radius: 8px;
            background: var(--neutral-100);
          }

          .drag-area {
            width: 100%;
            height: 100%;
            padding: 20px;
            font-weight: bold;
            font-size: 16px;
            line-height: 130%;
            color: var(--type-active);
            text-align: center;

            .separator {
              margin: 12px 0px;
            }

            .add-btn {
              background: var(--blue-700);
              border-radius: 8px;
              color: var(--neutral-000);
              padding: 8px 15px;
              cursor: pointer;

              font-family: Nunito Sans;
              font-size: 12px;
              font-style: normal;
              font-weight: 700;
              line-height: 16px;
              letter-spacing: 0em;
              text-align: left;
            }

            #add-file-input {
              display: none;
            }

            .file {
              display: flex;
              height: 100%;
              align-items: center;
              justify-content: space-between;
              align-content: center;

              svg {
                width: 42px !important;
                height: 42px !important;
              }

              .close {
                width: 20px !important;
                height: 20px !important;
              }
            }
          }
        }
      }

      .save-file {
        float: right;
        padding: 8px;
        margin-top: 20px;
        color: var(--neutral-000);
        background: var(--blue-500);
        border-radius: 8px;
      }
    }

    .modal-footer {
      .modal-footer-content {
        display: flex;
        justify-content: right;
        margin: 24px;

        button {
          width: auto;
          height: auto;
          padding: 14px 16px;
          font-weight: 600;
          font-size: 18px;
          line-height: 28px;
          border-radius: 8px;
          background-color: var(--blue-500);
          color: var(--neutral-000);
        }
      }
    }

    .md-content {
      padding: 30px 20px 10px 20px;
    }
  }
}
</style>

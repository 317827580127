<template>
  <div>
    <v-tiss-guide-file-modal
      :tissGuide="tissGuide"
      :file="file"
      @uploadNewDocument="uploadNewDocument"
      @update-file="updateFile"
      @clean-modal="clearFile"
    />

    <v-tiss-guide-file-list-modal
      :tissGuide="tissGuide"
      :files="files"
      @open-tiss-guide-file-modal="openDocumentModal"
      @edit-tiss-guide-file="openEditFileModal"
      @delete-file="deleteFile"
      @hide-file-list-modal="hideFileListModal"
    />
  </div>
</template>

<script>
import TissGuideFileModal from '@/components/Schedule/TissGuideFileModal'
import TissGuideFileListModal from '@/components/Schedule/TissGuideFileListModal'

export default {
  name: 'TissGuideFilesContainer',
  props: {
    tissGuide: Object,
    tissGuides: Array,
    files: Array,
    file: Object,
    openDocumentModal: Function,
    openEditFileModal: Function,
    clearFile: Function
  },
  components: {
    'v-tiss-guide-file-modal': TissGuideFileModal,
    'v-tiss-guide-file-list-modal': TissGuideFileListModal
  },
  methods: {
    deleteFile(file) {
      const tissGuide = this.tissGuides.find(
        guide => guide.id === file.tiss_guide_id
      )
      const index = this.files.findIndex(guide => guide.id === file.id)
      this.files.splice(index, 1)
      const index2 = this.tissGuides.findIndex(
        guide => guide.id === file.tiss_guide_id
      )
      this.$set(this.tissGuides, index2, tissGuide)
      this.clearFile()
    },
    updateFile(file) {
      const tissGuide = this.tissGuides.find(
        guide => guide.id === file.tiss_guide_id
      )
      const index = this.files.findIndex(guide => guide.id === file.id)
      this.files.splice(index, 1, file)

      const index2 = this.tissGuides.findIndex(
        guide => guide.id === file.tiss_guide_id
      )
      this.$set(this.tissGuides, index2, tissGuide)
      this.clearFile()
    },
    uploadNewDocument(file) {
      const tissGuide = this.tissGuides.find(
        guide => guide.id === file.tiss_guide_id
      )
      tissGuide.files_count++
      const index = this.tissGuides.findIndex(
        guide => guide.id === file.tiss_guide_id
      )
      this.$set(this.tissGuides, index, tissGuide)
      this.files.push(file)
      this.clearFile()
    },
    hideFileListModal(files) {
      this.$emit('hide-file-list-modal', files)
    }
  }
}
</script>